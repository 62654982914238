import { IconDownload } from '@veneer/core/dist/scripts/icons'
import React from 'react'
import type { Statement } from '@/types/Statements'
import { publishEvent, StatementsDownloadLinkClicked } from '@/utils/analytics'
import getOneTimeToken from '@/helpers/getOneTimeToken'

type StatementOneTimeTokenDownloadProps = {
  statement: Partial<Statement>
}

export const getDownloadLink = async (apiLink: string): Promise<string> => {
  const oneTimeToken = await getOneTimeToken(apiLink)
  return `${process.env.INSTANT_INK_URL}${apiLink}?key=${oneTimeToken}`
}

export default function StatementOneTimeTokenDownload({
  statement
}: StatementOneTimeTokenDownloadProps) {
  return (
    <div
      data-testid="statement-one-time-dl-link"
      onClick={() => {
        publishEvent(StatementsDownloadLinkClicked)
        getDownloadLink(statement.apiLink).then((downloadLink) =>
          window.open(downloadLink, '_blank')
        )
      }}
    >
      <IconDownload color="colorHpBlue6" />
    </div>
  )
}
