import Select from '@veneer/core/dist/scripts/select'
import Table from '@veneer/core/dist/scripts/table'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const breakPoints = {
  xl: '(min-width: 1738px)',
  lg: '(min-width: 1008px)',
  md: '(min-width: 1007px)',
  sm: '(min-width: 640px)',
  maxXl: '(max-width: 1738px)',
  maxLg: '(max-width: 1008px)',
  maxMd: '(max-width: 1007px)',
  maxSm: '(max-width: 640px)'
}

export const StyledBaseContainer = styled.div`
  margin: 40px;
  font-family: ${tokens.fontTextRegular};
`

export const StyledParagraph = styled.p`
  margin-bottom: ${tokens.space4};
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
  font-weight: 400;
  color: ${tokens.colorGray10};
`

export const StyledTitle = styled.h1`
  font-family: ${tokens.fontTitleRegular};
  margin-bottom: ${tokens.space2};
  margin-top: ${tokens.space4};
  font-weight: 400;
  font-size: ${tokens.fontSize8};
  line-height: ${tokens.lineHeight6};
  color: ${tokens.colorGray10};
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;
`

export const StyledActionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.space8};
  justify-content: space-between;
  margin-bottom: ${tokens.space6};
  width: 100%;
  @media ${breakPoints.sm} {
    flex-direction: row;
  }
`

export const StyledSelect = styled(Select)`
  max-width: 270px;
  @media ${breakPoints.maxSm} {
    max-width: 100%;
  }
`
