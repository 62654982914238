import React from 'react'
import Card from '../Card'
import { CardContent } from './styles'
import Header from './Header'

type ErrorInformationCardProps = {
  errorIdentifier: string
}

function ErrorInformationCard({ errorIdentifier }: ErrorInformationCardProps) {
  return (
    <Card>
      <CardContent data-testid="error-information-card">
        <Header errorIdentifier={errorIdentifier} />
      </CardContent>
    </Card>
  )
}
export default ErrorInformationCard
