import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { breakPoints } from '../Statements/styles'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${tokens.colorWhite};
  border-color: ${tokens.colorGray3};
  border-radius: ${tokens.size2};
  border-style: solid;
  border-width: 1px;
  margin: auto;
  min-height: 532px;
  margin-top: ${tokens.space6};
  @media ${breakPoints.maxSm} {
    min-height: 250px;
  }
`

export const StyledText = styled.p`
  margin: ${tokens.size4};
  color: ${tokens.colorGray10};
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};
`
