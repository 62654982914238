import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu'
import { TableColumns } from '@veneer/core/dist/scripts/table'
import StatementDownloadLink from '../StatementDownloadLink'
import useStatements from '@/hooks/useStatements'
import useAppActions from '@/hooks/useAppActions'
import useGetText from '@/hooks/useGetText'
import ErrorInformationCard from '../ErrorInformationCard'
import NoStatements from '../NoStatements'
import DOMPurify from 'dompurify'
import {
  StyledActionItemsContainer,
  StyledBaseContainer,
  StyledParagraph,
  StyledSelect,
  StyledTable,
  StyledTitle
} from './styles'
import {
  publishEvent,
  StatementsHistoryDropdownCollapsed,
  StatementsHistoryDropdownExpanded,
  StatementsScreenDisplayed
} from '@/utils/analytics'
import { getFormatDate, getPastMonthsDate } from '@/helpers/date-helper'
import { Statement } from '@/types/Statements'
import StatementOneTimeTokenDownload from '../StatementOneTimeTokenDownload'

const fetchKey = 'statements'

const DEFAULT_TOTAL_ITEMS = 0
const DEFAULT_STARTING_PAGE = 1
const DEFAULT_PAGE_SIZE = 5
const PAGE_SIZE_OPTIONS: OptionInterface<number>[] = [
  { value: 5 },
  { value: 25 },
  { value: 50 }
]

const STATEMENT_PERIOD_INDEX = {
  last3Months: 0,
  last6Months: 1,
  last12Months: 2,
  allHistory: 3
}

const filterDate = (date: string) => getFormatDate(new Date(date))
const getDatePastByMonths = (month: number) => getPastMonthsDate(month)

const preferences = {
  width: [
    { columnId: 'date', width: '132px' },
    { columnId: 'description', width: 'auto' },
    { columnId: 'downloadLink', width: '52px' }
  ]
}

const formatDescription = (statement: Statement) => {
  const strDesc = DOMPurify.sanitize(
    statement?.invoiceLineItems
      ?.map((invoiceLineItem) =>
        invoiceLineItem.description.replace(/<[^>]{1,7}>/g, '')
      )
      .join(', ') || statement.description
  )

  return <div dangerouslySetInnerHTML={{ __html: strDesc }} />
}

function Statements() {
  const getText = useGetText(fetchKey)
  const [historyValue, setHistoryValue] = useState(
    STATEMENT_PERIOD_INDEX.last3Months
  )
  const [currentPage, setCurrentPage] = useState(DEFAULT_STARTING_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const { setIsFetching, setErrorFetch, setSucessFetch } = useAppActions()

  const STATEMENT_PERIODS = [
    {
      value: STATEMENT_PERIOD_INDEX.last3Months,
      label: getText('options.last3Months'),
      date: getDatePastByMonths(3)
    },
    {
      value: STATEMENT_PERIOD_INDEX.last6Months,
      label: getText('options.last6Months'),
      date: getDatePastByMonths(6)
    },
    {
      value: STATEMENT_PERIOD_INDEX.last12Months,
      label: getText('options.last12Months'),
      date: getDatePastByMonths(12)
    },
    {
      value: STATEMENT_PERIOD_INDEX.allHistory,
      label: getText('options.allHistory'),
      date: '1970-01-01'
    }
  ]
  const TABLE_COLUMNS: TableColumns[] = [
    { id: 'date', label: getText('table.columns.date') },
    { id: 'description', label: getText('table.columns.description') },
    { id: 'downloadLink', label: '' }
  ].map((column) => ({
    ...column,
    label: column.label?.toUpperCase() || ''
  }))

  const { info } = useStatements({
    fromDate: STATEMENT_PERIODS[historyValue].date,
    toDate: getPastMonthsDate(0)
  })

  const onHistorySelectChange = (selectedOption) => {
    setHistoryValue(selectedOption.value)
    if (selectedOption.value === STATEMENT_PERIOD_INDEX.allHistory) {
      publishEvent(StatementsHistoryDropdownExpanded)
      publishEvent(StatementsHistoryDropdownCollapsed)
    }
    setIsFetching(fetchKey, false)
    setErrorFetch(fetchKey, null)
    setSucessFetch(fetchKey, null)
    setCurrentPage(DEFAULT_STARTING_PAGE)
  }
  const onPageChange = useCallback((page) => setCurrentPage(page), [])

  const onPageSizeChange = useCallback((_event, { value }) => {
    setCurrentPage(1)
    setPageSize(value)
  }, [])

  const filteredTable = useMemo(() => {
    const table = info?.data?.map((statement: Statement) => ({
      date: filterDate(statement.invoiceDate),
      description: formatDescription(statement),
      downloadLink:
        statement.accessType === 'ONE_TIME_TOKEN' ? (
          <StatementOneTimeTokenDownload statement={statement} />
        ) : (
          <StatementDownloadLink downloadLink={statement.downloadLink} />
        ),
      rowConfig: {
        noWrap: true
      },
      key: statement.customerId
    }))
    return (
      table?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      ) || []
    )
  }, [info?.data, currentPage, pageSize])

  useEffect(() => {
    publishEvent(StatementsScreenDisplayed)
  }, [])

  if (info.error === 'generic' || info.error === 'unauthorized') {
    return <ErrorInformationCard errorIdentifier={info.error} />
  }
  return (
    <StyledBaseContainer>
      <StyledTitle>{getText('body.title')}</StyledTitle>
      {!info.isFetching &&
      (info.error === 'noStatements' || filteredTable.length === 0) ? (
        <NoStatements message={getText('error.noStatements.message')} />
      ) : (
        <>
          <StyledParagraph>{getText('body.description')}</StyledParagraph>
          <StyledActionItemsContainer>
            <StyledSelect
              data-testid="history-select"
              options={STATEMENT_PERIODS}
              onChange={onHistorySelectChange}
              clearIcon={false}
              defaultValue={[historyValue]}
              value={[historyValue]}
              disabled={info.isFetching}
            />
          </StyledActionItemsContainer>
          <StyledTable
            columns={TABLE_COLUMNS}
            data={filteredTable}
            loading={info?.isFetching}
            loadingDataLength={pageSize}
            preferences={preferences}
            pagination={{
              currentPage,
              onPageChange,
              pageSize,
              onPageSizeChange,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              totalItems: info?.data?.length || DEFAULT_TOTAL_ITEMS
            }}
          />
        </>
      )}
    </StyledBaseContainer>
  )
}

export default memo(Statements)
