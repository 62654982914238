import StatementsClient from '@/services/StatementsClient'
import useAccountMgtSvcClient from '../useAccountMgtSvcClient'
import { useFlags } from 'launchdarkly-react-client-sdk'

const statementsClientRef = { current: null }

export default function useStatementsClient() {
  const accountMgtSvcClient = useAccountMgtSvcClient()
  const { loadInstantInkStatements } = useFlags()
  if (!statementsClientRef.current && loadInstantInkStatements !== undefined)
    statementsClientRef.current = new StatementsClient(
      accountMgtSvcClient,
      loadInstantInkStatements
    )

  return statementsClientRef.current
}
