import { InstantInkType } from 'src/types/InstantInkStatement'
import { Statement } from 'src/types/Statements'

export const prepareStatements = (invoices: Array<InstantInkType>) => {
  const prepareList = invoices.map((invoice) => {
    return ({
      invoiceDate: invoice.date,
      description: invoice.description,
      apiLink: invoice.link,
      customerId: invoice.description,
      accessType: 'ONE_TIME_TOKEN'
    } as unknown) as Statement
  })
  return prepareList
}
