import { AnalyticsEventType } from '@/types/AnalyticsEvent'

const activity = 'AcctMgmt-v01'
const screenPath = '/InvoicesManagementReact/'
const screenName = 'Statements'
const eventDetailVersion = '1.3.0'

export const publishEvent = (event: Partial<AnalyticsEventType>) => {
  const publishCdmEvents = window?.Shell?.v1?.analytics?.publishCdmEvents

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ])
}

export const StatementsScreenDisplayed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ScreenDisplayed',
  screenPath,
  screenName,
  version: eventDetailVersion
}

export const StatementsHistoryDropdownExpanded: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlAccordianExpanded',
  screenPath,
  screenName,
  controlName: 'StatementAllHistory',
  version: eventDetailVersion
}
export const StatementsHistoryDropdownCollapsed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlAccordianCollapsed',
  screenPath,
  screenName,
  controlName: 'StatementAllHistory',
  version: eventDetailVersion
}

export const StatementsDownloadLinkClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName,
  controlName: 'Download',
  version: eventDetailVersion
}
