export default function getLocation(): { country?: string; language?: string } {
  const [country, language] = window.location.pathname
    .substring(1, '__/__'.length + 1)
    .split('/')
    .filter((text) => text)

  return {
    country: country ?? 'US',
    language: language ?? 'en'
  }
}

export const getLocaleFromLocation = (location) =>
  location?.language?.toLowerCase() + '-' + location?.country?.toUpperCase()

export const getNavigatorLanguage = () => {
  const location = getLocation()
  const localeFromLocation = getLocaleFromLocation(location)

  if (localeFromLocation.length === 5) {
    return localeFromLocation
  }

  return navigator?.languages?.[0] || 'en-US'
}
