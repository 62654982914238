import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import useShellProps from '../useShellProps'

const accountMgtSvcClientRef = { current: null }

export default function useAccountMgtSvcClient() {
  const { authProvider, stack } = useShellProps()

  if (!accountMgtSvcClientRef.current)
    accountMgtSvcClientRef.current = new AccountMgtSvcClient(
      stack,
      authProvider
    )

  return accountMgtSvcClientRef.current
}
