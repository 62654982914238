import { createContext } from 'react'
import AppDispatch from '../types/AppDispatch'
import StatementsAppState from '../types/StatementsAppState'

const AppContext = createContext<{
  state?: StatementsAppState
  dispatch?: AppDispatch
}>(undefined)

export default AppContext
