import { setAssetsProvider } from './Actions'
import setSucessFetch from './setSucessFetch'
import setIsFetching from './setIsFetching'
import setErrorFetch from './setErrorFetch'

export type AppActionsType =
  | ReturnType<typeof setAssetsProvider>
  | ReturnType<typeof setSucessFetch>
  | ReturnType<typeof setIsFetching>
  | ReturnType<typeof setErrorFetch>

export default {
  setAssetsProvider,
  setSucessFetch,
  setErrorFetch,
  setIsFetching
}
