import axios from 'axios'
import getStratusToken from '@/helpers/getStratusToken'
import InfoDatas from 'src/types/InfoDatas'
import { getNavigatorLanguage } from '@/helpers/getLocation'
import type Customer from '@/types/Customer'
import type {
  Statement,
  StatementDownloadLinkList,
  GetCommerceManagementStatementsType
} from '@/types/Statements'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import { prepareStatements } from './StatementClient.utils'
import { IICreditNoteType, InstantInkType } from 'src/types/InstantInkStatement'
import { HeadersType } from 'src/types/Headers'
import toDateObject from '@/utils/toDate'

type AccountClient = Pick<AccountMgtSvcClient, 'getAccount'>
export default class StatementsClient {
  private accountClient: AccountClient
  private loadInstantInkStatements: boolean
  constructor(
    accountClient: AccountClient,
    loadInstantInkStatements?: boolean
  ) {
    axios.defaults.params = {}
    axios.defaults.params['locale'] = getNavigatorLanguage()
    this.accountClient = accountClient
    this.loadInstantInkStatements = loadInstantInkStatements
  }

  mergeStatements(
    commerceManagementStatements: Statement[],
    InstantInkStatements: Statement[]
  ) {
    return commerceManagementStatements
      ? InstantInkStatements.concat(commerceManagementStatements)
      : InstantInkStatements
  }

  async getStatements(
    props: Record<string, string>
  ): Promise<InfoDatas['statements'] | Statement[]> {
    const { fromDate, toDate } = props
    const stratusToken = await getStratusToken()
    const headers = {
      authorization: `Bearer ${stratusToken}`
    }

    const InstantInkStatements = this?.loadInstantInkStatements
      ? this.getInstantInkStatements(headers)
      : Promise.resolve([])
    const commerceManagementStatements = this.getCommerceManagementStatements({
      headers,
      fromDate,
      toDate
    })

    const allStatements = Promise.all([
      InstantInkStatements,
      commerceManagementStatements
    ]).then((values) => {
      const InstantInkStatements = values[0]
      const commerceManagementStatements = values[1]
      const mergedStatements = this.mergeStatements(
        commerceManagementStatements,
        InstantInkStatements
      )

      const filteredData = this.filterAndOrderData(
        mergedStatements,
        toDateObject(fromDate),
        toDateObject(toDate)
      )
      return filteredData
    })
    return allStatements
  }

  async getCommerceManagementStatements({
    headers,
    fromDate,
    toDate
  }: GetCommerceManagementStatementsType) {
    const statementsDownloadLinkArray = []
    const {
      data: { resourceId: tenant_id }
    } = await this.accountClient.getAccount()
    const { resourceId } = await this.getCustomerId(tenant_id)
    try {
      const { data: statements } = await axios.get<InfoDatas['statements']>(
        `${process.env.COMMERCE_MANAGEMENT_URL}/customers/${resourceId}/invoice-data`,
        {
          headers,
          params: {
            fromDate,
            toDate
          }
        }
      )
      statements.forEach((statement) => {
        statementsDownloadLinkArray.push(
          axios.get<StatementDownloadLinkList>(
            `${process.env.COMMERCE_MANAGEMENT_URL}/${
              statement.resourceType === 'Purchase' ? 'v2/purchases' : 'orders'
            }/${statement.resourceId}/invoices`,
            { headers }
          )
        )
      })
      const statementsDownloadLinks = await this.getStatementsDownloadLinks(
        statementsDownloadLinkArray
      )
      return statements?.map((statement: Statement, index: number) => {
        const downloadLinkForStatement = statementsDownloadLinks[index]
        if (downloadLinkForStatement.status === 'fulfilled') {
          const selectedStatement =
            downloadLinkForStatement.value.data.invoiceList[0]
          return {
            ...statement,
            downloadLink: selectedStatement
          }
        } else if (
          downloadLinkForStatement.status === 'rejected' &&
          downloadLinkForStatement.reason.response.status === 404
        ) {
          return {
            ...statement,
            downloadLink: null
          }
        } else {
          return statement
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  async getInstantInkStatements(headers: HeadersType): Promise<Statement[]> {
    const invoice = axios
      .get<Array<InstantInkType>>(`${process.env.COMFE_URL}/tenant/invoice`, {
        headers
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          return Promise.resolve({ data: new Array<InstantInkType>() })
        }
        return Promise.reject(error)
      })
    const creditNote = axios
      .get<Array<IICreditNoteType>>(
        `${process.env.COMFE_URL}/tenant/credit_note`,
        {
          headers
        }
      )
      .catch((error) => {
        if (error?.response?.status === 404) {
          return Promise.resolve({ data: new Array<IICreditNoteType>() })
        }
        return Promise.reject(error)
      })

    const oneTimePurchase = axios
      .get<Array<InstantInkType>>(
        `${process.env.COMFE_URL}/tenant/one_time_purchase`,
        {
          headers
        }
      )
      .catch((error) => {
        if (error?.response?.status === 404) {
          return Promise.resolve({ data: new Array<InstantInkType>() })
        }
        return Promise.reject(error)
      })
    const instantInkStatements = Promise.all([
      invoice,
      creditNote,
      oneTimePurchase
    ]).then((values) => {
      const { data: invoices } = values?.[0]
      const { data: creditNotes } = values?.[1]
      const { data: oneTimePurchase } = values?.[2]

      let allInstantInkStatements = []
      let prepareCreditNote = []
      let prepareInvoiceList = []
      let prepareOneTime = []

      if (creditNotes?.length > 0) {
        const creditNoteList = creditNotes.reduce((acc, invoice) => {
          const { credit_notes: creditNotesArray } = invoice
          return [...acc, ...creditNotesArray]
        }, [])
        prepareCreditNote = prepareStatements(creditNoteList)
        allInstantInkStatements = allInstantInkStatements.concat(
          prepareCreditNote
        )
      }

      if (invoices?.length > 0) {
        prepareInvoiceList = prepareStatements(invoices)
        allInstantInkStatements = allInstantInkStatements.concat(
          prepareInvoiceList
        )
      }
      if (oneTimePurchase?.length > 0) {
        prepareOneTime = prepareStatements(oneTimePurchase)
        allInstantInkStatements = allInstantInkStatements.concat(prepareOneTime)
      }
      return allInstantInkStatements
    })
    return instantInkStatements
  }

  async getStatementsDownloadLinks(
    statementsDownloadLinkArray
  ): Promise<PromiseSettledResult<{ data: StatementDownloadLinkList }>[]> {
    return await Promise.all(
      statementsDownloadLinkArray.map((promise) =>
        promise
          .then((value) => ({
            status: 'fulfilled',
            value
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason
          }))
      )
    )
  }
  async getCustomerId(tenantId: string): Promise<Customer> {
    const stratusToken = await getStratusToken()
    const customer = await axios
      .get<Customer>(`${process.env.COMMERCE_MANAGEMENT_URL}/customers`, {
        headers: {
          authorization: `Bearer ${stratusToken}`
        },
        params: {
          userTenantId: tenantId
        }
      })
      .then((customer) => customer.data)
    return customer
  }

  filterAndOrderData = (
    statements: Statement[],
    fromDate: Date,
    toDate: Date
  ) => {
    return statements
      ?.filter((statement) => {
        const invoiceDate = new Date(statement.invoiceDate)
        return fromDate <= invoiceDate && invoiceDate <= toDate
      })
      .sort(
        (st1, st2) =>
          new Date(st2.invoiceDate).getTime() -
          new Date(st1.invoiceDate).getTime()
      )
  }
}
