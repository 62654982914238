import React from 'react'
import StatementsContainer from '../src/components/StatementsContainer'
import projectNames from '../src/configs/projectNames'
import { ThemeProvider, ThemeContextInterface } from '@veneer/theme'
import { MfePropsType } from '../src/types/mfeProps'
import { ShellProps } from '../src/types/shell'

declare global {
  interface Window {
    Shell: ShellProps
  }
}
/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType) {
  const themeMode = props.mode
    ? (props.mode as ThemeContextInterface['mode'])
    : 'light'
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ThemeProvider mode={themeMode}>
        <StatementsContainer {...props} />
      </ThemeProvider>
    </section>
  )
}
