import get from 'lodash/get'
import set from 'lodash/set'
import merge from 'lodash/merge'
import type StatementsAppState from '@/types/StatementsAppState'

const assignState = (
  state: StatementsAppState,
  path: string,
  value: unknown,
  mustMerge = false
) => {
  let newValue = value
  const oldValue = get(state, path)

  if (mustMerge && typeof value === 'object' && typeof newValue === 'object') {
    newValue = merge(value, oldValue)
  }

  return { ...set(state, path, newValue) } as StatementsAppState
}

export default assignState
