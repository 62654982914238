import { useEffect, useCallback } from 'react'
import InfoKeys from '@/types/InfoKeys'
import useAppContext from '@/hooks/useAppContext'
import useAppActions from '../useAppActions'
import InfoDatas from '@/types/InfoDatas'
import StatementsError from '@/types/StatementsError'

const loading: Partial<Record<InfoKeys, boolean>> = {}
export default function useDataFetcher<K extends InfoKeys>(
  key: K,
  fetcher?: () => Promise<InfoDatas[K]>
) {
  const { [key]: info } = useAppContext().state
  const { setIsFetching, setErrorFetch, setSucessFetch } = useAppActions()

  const asyncGetInfo = async (force = false) => {
    const { data, error } = info
    const shouldFetchData = !data && !error && !loading[key]

    if (shouldFetchData || force) {
      loading[key] = true
      setIsFetching(key, true)
      try {
        setSucessFetch(key, await fetcher())
      } catch (e) {
        console.error(e)
        const responseStatus = e?.response?.status
        let errorType: StatementsError
        if (responseStatus === 401) {
          errorType = 'unauthorized'
        } else {
          errorType = 'generic'
        }
        setErrorFetch(key, errorType)
      } finally {
        loading[key] = false
        setIsFetching(key, false)
      }
    }
  }

  const forceRefresh = useCallback(async () => {
    if (fetcher) asyncGetInfo(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher])

  useEffect(() => {
    if (fetcher) asyncGetInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher])

  return {
    info,
    forceRefresh
  }
}
