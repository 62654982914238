import { useCallback } from 'react'
import useAssetsProvider from '../useAssetsProvider'

const useGetText = (prefixKey?: string) => {
  const assetsProvider = useAssetsProvider()

  return useCallback(
    (subkey?: string, keys?: Record<string, string>) => {
      const key = [prefixKey, subkey].filter((e) => e).join('.')
      return assetsProvider?.getText(key, keys)
    },
    [assetsProvider, prefixKey]
  )
}

export default useGetText
