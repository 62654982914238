import * as React from 'react'
import { ShellProvider } from '@/context/shellContext'
import setEnvironmentVariables from '@/helpers/setEnvironmentVariables'
import { getStack } from '@/helpers/getStack'
import { MfePropsType } from '@/types/mfeProps'
import AppProvider from '@/context/AppProvider'
import Statements from '../Statements'
import LaunchDarkly from '../LaunchDarkly/LaunchDarkly'

const StatementsContainer = (props: MfePropsType) => {
  setEnvironmentVariables(getStack(props.stack))
  return (
    <LaunchDarkly>
      <AppProvider>
        <ShellProvider value={props}>
          <Statements />
        </ShellProvider>
      </AppProvider>
    </LaunchDarkly>
  )
}

export default StatementsContainer
