import React from 'react'
import { Container, StyledText } from './styles'

interface NoStatementsProps {
  message: string
}

function NoStatements({ message }: NoStatementsProps) {
  return (
    <Container>
      <StyledText>{message}</StyledText>
    </Container>
  )
}

export default NoStatements
