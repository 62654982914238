import {
  IconArrowsCorners,
  IconDownload
} from '@veneer/core/dist/scripts/icons'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import React from 'react'
import useGetText from '@/hooks/useGetText'
import type { StatementDownloadLink as StatementDownloadLinkType } from '@/types/Statements'
import { publishEvent, StatementsDownloadLinkClicked } from '@/utils/analytics'

type StatementDownloadLinkProps = {
  downloadLink: StatementDownloadLinkType
}

export default function StatementDownloadLink({
  downloadLink
}: StatementDownloadLinkProps) {
  const getText = useGetText('statements.table.downloadLink')
  if (!downloadLink) {
    return (
      <div data-testid="statement-unavailable-dl-link">
        <Tooltip
          content={<p>{getText('downloadLater')}</p>}
          id="tooltip"
          placement="left"
          contentHideDelayOnHover={300}
          contentShowDelayOnHover={300}
          arrow
        >
          <IconArrowsCorners color="colorGray7" />
        </Tooltip>
      </div>
    )
  }
  const { data, filename, url, message } = downloadLink

  const documentTitle = filename || message

  return data ? (
    <div
      data-testid="statement-dl-link-data"
      onClick={() => {
        publishEvent(StatementsDownloadLinkClicked)
        const pdfWindow = window.open('', '_blank')
        pdfWindow.document.write(
          `<iframe id="${documentTitle}" type="text/html" width="100%" height="100%" style="border-width: 0" src="data:${data}"/>`
        )
        pdfWindow.document.body.style.margin = '0'
        pdfWindow.document.title = documentTitle
      }}
    >
      <IconDownload color="colorHpBlue6" />
    </div>
  ) : (
    <a
      href={url}
      target="_blank"
      data-testid="statement-dl-link"
      onClick={() => {
        publishEvent(StatementsDownloadLinkClicked)
      }}
      rel="noreferrer"
    >
      <IconDownload color="colorHpBlue6" />
    </a>
  )
}
