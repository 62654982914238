import { useContext } from 'react'
import AppContext from '@/context/AppContext'

const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error(
      'useAppContext must be used inside a App Provider component'
    )
  }
  return context
}
export default useAppContext
