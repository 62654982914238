import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const HeaderContainer = styled.div`
  text-align: center;
`

export const HeaderTitle = styled.h1`
  font-size: ${tokens.fontSize6};
  line-height: ${tokens.lineHeight6};
  color: #464d50;
`

export const HeaderMessage = styled.div`
  line-height: ${tokens.lineHeight4};
`
