import React from 'react'
import IconWarning from '@veneer/core/dist/scripts/icons/icon_warning'
import tokens from '@veneer/tokens'
import useGetText from '@/hooks/useGetText'
import {
  HeaderContainer,
  HeaderIcon,
  HeaderTitle,
  HeaderMessage
} from './styles'

type HeaderProps = {
  errorIdentifier: string
}

export default function Header({ errorIdentifier }: HeaderProps) {
  const getErrorText = useGetText('statements.error')

  const header = {
    icon: <IconWarning size={52} style={{ fill: tokens.colorOrange7 }} />,
    title: getErrorText(errorIdentifier + '.title'),
    message: getErrorText(errorIdentifier + '.message')
  }
  return (
    <HeaderContainer>
      <HeaderIcon>{header.icon}</HeaderIcon>
      <HeaderTitle>{header.title}</HeaderTitle>
      <HeaderMessage>{header.message}</HeaderMessage>
    </HeaderContainer>
  )
}
