import useShellContext from '@/hooks/useShellContext'

const useShellProps = () => {
  const shellContext = useShellContext()
  let language = shellContext.language?.toString().toLowerCase()
  let country = shellContext.country?.toString().toUpperCase()

  language = language ? language : process.env.DEFAULT_LANGUAGE || 'en'
  country = country ? country : process.env.DEFAULT_COUNTRY || 'US'
  return {
    language,
    country,
    addressId: shellContext.addressId,
    authProvider: shellContext.authProvider || window?.Shell?.v1?.authProvider,
    onSave: shellContext.onSave,
    onCancel: shellContext.onCancel,
    stack: shellContext.stack
  }
}

export default useShellProps
