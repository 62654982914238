import { AssetsProvider } from '@jarvis/web-assets-provider'

export enum ActionsType {
  SET_ERROR = 'SET_ERROR',
  SET_SUCCESS_FETCH = 'SET_SUCCESS_FETCH',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_ERROR_FETCH = 'SET_ERROR_FETCH',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER'
}

export type Actions = {
  type: ActionsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

type ReturnSetAssetsProvider = {
  type: ActionsType.SET_ASSETS_PROVIDER
  data: AssetsProvider
}

export const setAssetsProvider = (
  assetsProvider: AssetsProvider
): ReturnSetAssetsProvider => ({
  type: ActionsType.SET_ASSETS_PROVIDER,
  data: assetsProvider
})
