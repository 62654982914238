import getStratusToken from '@/helpers/getStratusToken'
import axios from 'axios'

export default async function getOneTimeToken(invoice_link: string) {
  const stratusToken = await getStratusToken()
  try {
    const { data } = await axios.post<{ invoice_token: string }>(
      `${process.env.COMFE_URL}/tenant/invoice/token?invoice_link=${invoice_link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${stratusToken}`
        }
      }
    )
    return data.invoice_token
  } catch (e) {
    console.error(e)
  }
}
